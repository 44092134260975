<template>
  <div>
    <div class="aside-title">新闻资讯</div>
    <div class="title-sub">NEWS INFORMATION</div>
    <div v-for="(item, index) in list" :key="index">
      <div class="news-title">{{ item.title }}</div>
      <div>
        <a
          v-for="(news, nindex) in item.news"
          :key="nindex"
          class="acea-row news-row"
          :href="'/news/' + news.id"
        >
          <div class="line1 flex-con">{{ news.title }}</div>
          <div>{{ news.add_time }}</div>
        </a>
      </div>
      <div style="text-align: right">
        <a class="btn-more" :href="'/news/list?cid=' + item.id"
          >了解更多 <i class="iconfont">&#xe616;</i></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { formatDate } from "@/utils/index";

export default {
  name: "AsideNewsShort",
  props: {
    count: {
      type: Number,
      default: 2,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.articleCategory();
  },
  methods: {
    articleCategory() {
      request.get("article/category/list?pid=0").then((res) => {
        let navs = res.data;
        let ln = Math.min(navs.length, this.count + 1);
        for (let i = 1; i < ln; i++) {
          navs[i].news = [];
          this.list.push(navs[i]);
        }

        this.list.forEach((item, index) => {
          this.getArticleLists(item.id, index);
        });
      });
    },
    getArticleLists(id, index) {
      request
        .get("article/list/" + id, {
          page: 1,
          limit: this.limit,
        })
        .then((res) => {
          this.list[index].news = (res.data || []).map((item) => {
            let time = new Date(item.add_time).getTime();
            item.add_time = formatDate("m-d", parseInt(time / 1000));
            return item;
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.aside-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 6px;
}

.news-title {
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 4px 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  background: #100965;
}
.news-row {
  margin: 10px 0;
  font-size: 15px;
  color: #666;
  cursor: pointer;
}
.btn-more {
  display: inline-block;
  font-size: 12px;
  color: #359dde;
  border: 1px solid #2e9ede;
  border-radius: 2px;
  padding: 4px 8px;
  cursor: pointer;

  .iconfont {
    font-size: 0.8em;
  }
}
</style>