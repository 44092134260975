<template>
  <div>
    <div class="pc-wrap" style="position: relative">
      <Swiper
        ref="banner"
        class="banner"
        :style="{ height: bannerH + 'px' }"
        :options="bannerOptions"
      >
        <SwiperSlide
          v-if="stars.length"
          class="banner-item banner-earth pc-wrap"
        >
          <div class="pc-content">
            <div class="earth-title-box phone-hide">
              <div class="earth-title-sub">链接润泽汇 一切皆有可能</div>
              <div class="earth-title">
                <span class="mr-20">服务</span>
                <span class="mr-20 mark">合作</span>
                <span class="mr-20">共享</span>
                <span class="mark">创新</span>
              </div>
            </div>
            <div class="earth-title-m phone-show">
              <span class="mr-10 mark">服务</span>
              <span class="mr-10">合作</span>
              <span class="mr-10 mark">共享</span>
              <span>创新</span>
              <div class="earth-subtitle phone-show">品质新生活引领者</div>
            </div>

            <Earth
              ref="earth"
              class="earth-box"
              :boxWidth="boxWidth"
              :dots="stars"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          v-for="(item, index) in banner"
          :key="index"
          class="banner-item img"
          :style="{ 'background-image': 'url(' + item.pic + ')' }"
          @click.native="$jump(item.url)"
        ></SwiperSlide>
      </Swiper>
      <div v-if="bannerSize > 1" class="swiper-pagination">
        <span
          v-for="(item, index) in bannerSize"
          :key="index"
          class="swiper-pagination-item"
          :class="{ on: bannerIndex == index }"
        ></span>
      </div>
    </div>
    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="pt-row acea-row">
          <div class="pt-item flex-con">
            <div class="pt-num">200<i class="iconfont">&#xe633;</i></div>
            <div>服务企业</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">20000<i class="iconfont">&#xe633;</i></div>
            <div>优质用户</div>
          </div>
          <div class="pt-item flex-con">
            <div class="pt-num">500<i class="iconfont">&#xe633;</i></div>
            <div>覆盖城市</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content" style="over">
        <div class="title">信鸽数字平台</div>
        <div class="subtitle"></div>
        <div class="box-pt phone-hide">
          <div class="box-pt-img">
            <img src="@/assets/images/bg-2-06.png" alt="" />
          </div>
          <div class="fix-pt">
            <div class="fix-pt-row fmix-align">
              <div class="name">信鸽数字社交</div>
              <div class="fix-pt-icons flex-con fmix-align-sb">
                <router-link class="serve-icon-item" to="/news/38">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-01.png" alt="" />
                  </div>
                  <div class="serve-icon-name">企业IP</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/39">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-02.png" alt="" />
                  </div>
                  <div class="serve-icon-name">个人IP</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/40">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-03.png" alt="" />
                  </div>
                  <div class="serve-icon-name">社团IP</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/41">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-04.png" alt="" />
                  </div>
                  <div class="serve-icon-name">品牌IP</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/42">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-05.png" alt="" />
                  </div>
                  <div class="serve-icon-name">产品IP</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/77">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-07.png" alt="" />
                  </div>
                  <div class="serve-icon-name">活动IP</div>
                </router-link>
              </div>
            </div>

            <div class="fix-pt-row fmix-align">
              <div class="name">信鸽商城</div>
              <div class="fix-pt-icons flex-con fmix-align-sb">
                <router-link class="serve-icon-item" to="/news/75">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-21.png" alt="" />
                  </div>
                  <div class="serve-icon-name">企业福采</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/76">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-22.png" alt="" />
                  </div>
                  <div class="serve-icon-name">企业集采</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/21">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-23.png" alt="" />
                  </div>
                  <div class="serve-icon-name">溯源商城</div>
                </router-link>
                <router-link class="serve-icon-item" to="/news/20">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-24.png" alt="" />
                  </div>
                  <div class="serve-icon-name">润品商城</div>
                </router-link>
              </div>
            </div>

            <!-- <div class="fix-pt-row fmix-align">
              <div class="name">企业福利内购</div>
              <div class="fix-pt-icons flex-con fmix-center">
                <img
                  class="bg"
                  src="@/assets/images/icon/icon-2-31.png"
                  alt=""
                />
                <router-link class="serve-icon-item" to="/news/64">
                  <div class="serve-icon">
                    <img src="@/assets/images/icon/icon-2-31.png" alt="" />
                  </div>
                  <div class="serve-icon-name">老板给利 福利来了</div>
                </router-link>
              </div>
            </div> -->
          </div>
        </div>

        <div class="box-serve phone-show">
          <div class="box-serve-item">
            <div class="flex-con serve-box">
              <img src="@/assets/images/bg-2-03.jpg" alt="" />
              <div class="serve-content">
                <div class="serve-title">信鸽数字社交</div>
                <div class="serve-title-sub">服务企业及赋能</div>
                <div class="serve-wrapper">
                  <div class="serve-icons">
                    <router-link class="serve-icon-item" to="/news/38">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-01.png" alt="" />
                      </div>
                      <div class="serve-icon-name">企业IP</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/39">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-02.png" alt="" />
                      </div>
                      <div class="serve-icon-name">个人IP</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/40">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-03.png" alt="" />
                      </div>
                      <div class="serve-icon-name">社团IP</div>
                    </router-link>
                  </div>
                  <div class="serve-icons">
                    <router-link class="serve-icon-item" to="/news/41">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-04.png" alt="" />
                      </div>
                      <div class="serve-icon-name">品牌IP</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/42">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-05.png" alt="" />
                      </div>
                      <div class="serve-icon-name">产品IP</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/77">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-07.png" alt="" />
                      </div>
                      <div class="serve-icon-name">活动IP</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-con serve-box">
              <img src="@/assets/images/bg-2-04.jpg" alt="" />
              <div class="serve-content">
                <div class="serve-title">信鸽商城</div>
                <div class="serve-title-sub">品牌让利 消费分红</div>
                <div class="serve-wrapper">
                  <div class="serve-icons">
                    <router-link class="serve-icon-item" to="/news/75">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-21.png" alt="" />
                      </div>
                      <div class="serve-icon-name">企业福采</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/76">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-22.png" alt="" />
                      </div>
                      <div class="serve-icon-name">企业集采</div>
                    </router-link>
                  </div>
                  <div class="serve-icons">
                    <router-link class="serve-icon-item" to="/news/21">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-23.png" alt="" />
                      </div>
                      <div class="serve-icon-name">溯源商城</div>
                    </router-link>
                    <router-link class="serve-icon-item" to="/news/20">
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-24.png" alt="" />
                      </div>
                      <div class="serve-icon-name">润品商城</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="flex-con serve-box">
              <img src="@/assets/images/bg-2-05.jpg" alt="" />
              <div class="serve-content">
                <div class="serve-title">企业内购</div>
                <div class="serve-title-sub">福利内购</div>
                <div class="serve-wrapper">
                  <div class="serve-icons xueyuan">
                    <router-link
                      class="serve-icon-item"
                      style="margin-top: 50px"
                      to="/news/64"
                    >
                      <div class="serve-icon">
                        <img src="@/assets/images/icon/icon-2-31.png" alt="" />
                      </div>
                      <div class="serve-icon-name">老板给利 福利来了</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">专项服务</div>
        <div class="subtitle"></div>
        <div class="zx-box">
          <router-link class="flex-con zx-item" to="/news/53">
            <img src="@/assets/images/bg-3-01.jpg" alt="" />
          </router-link>
          <router-link class="flex-con zx-item" to="/news/52">
            <img src="@/assets/images/bg-3-02.jpg" alt="" />
          </router-link>

          <div class="fix right">
            <AsideNewsShort />
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">集成服务</div>
        <div class="subtitle">企业的集成服务商</div>
        <div class="fuwu-box">
          <div class="fuwu-row">
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-01.png" alt="" />
                </div>
                <div class="fuwu-name">数字化转型</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">数字化转型</div>
                <router-link class="fuwu-mask-btn" to="/news/46"
                  >了解详情</router-link
                >
              </div>
            </div>
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-02.png" alt="" />
                </div>
                <div class="fuwu-name">SAAS工具赋能</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">SAAS工具赋能</div>
                <router-link class="fuwu-mask-btn" to="/news/47"
                  >了解详情</router-link
                >
              </div>
            </div>
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-03.png" alt="" />
                </div>
                <div class="fuwu-name">企业资源共享</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">企业资源共享</div>
                <router-link class="fuwu-mask-btn" to="/news/48"
                  >了解详情</router-link
                >
              </div>
            </div>
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-04.png" alt="" />
                </div>
                <div class="fuwu-name">商品溯源</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">商品溯源</div>
                <router-link class="fuwu-mask-btn" to="/news/49"
                  >了解详情</router-link
                >
              </div>
            </div>
            <!-- <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-05.png" alt="" />
                </div>
                <div class="fuwu-name">财税筹划</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">财税筹划</div>
                <router-link class="fuwu-mask-btn" to="/news/50"
                  >了解详情</router-link
                >
              </div>
            </div> -->
            <!-- </div>

          <div class="fuwu-row"> -->
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-06.png" alt="" />
                </div>
                <div class="fuwu-name">数字化营销</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">数字化营销</div>
                <router-link class="fuwu-mask-btn" to="/news/51"
                  >了解详情</router-link
                >
              </div>
            </div>
            <!-- <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-07.png" alt="" />
                </div>
                <div class="fuwu-name">定制用酒</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">定制用酒</div>
                <router-link class="fuwu-mask-btn" to="/news/52"
                  >了解详情</router-link
                >
              </div>
            </div>
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-08.png" alt="" />
                </div>
                <div class="fuwu-name">品质生活空间</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">品质生活空间</div>
                <router-link class="fuwu-mask-btn" to="/news/53"
                  >了解详情</router-link
                >
              </div>
            </div>
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-09.png" alt="" />
                </div>
                <div class="fuwu-name">平台积分互链</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">平台积分互链</div>
                <router-link class="fuwu-mask-btn" to="/news/54"
                  >了解详情</router-link
                >
              </div>
            </div> -->
            <div class="fuwu-item">
              <div class="fuwu-item-wrap">
                <div class="fuwu-icon">
                  <img src="@/assets/images/icon/icon-3-10.png" alt="" />
                </div>
                <div class="fuwu-name">对外开发系统</div>
              </div>
              <div class="fuwu-mask">
                <div class="fuwu-mask-name">对外开发系统</div>
                <router-link class="fuwu-mask-btn" to="/news/55"
                  >了解详情</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">数字商业联盟合作伙伴</div>
        <div class="subtitle">ALLIANCE PARTNER</div>
        <img src="@/assets/images/bg-4.png" alt="" />
        <div
          class="partner-box"
          :class="{ taggle: taggle_show }"
          :style="{ height: partnerBoxH + 'px' }"
        >
          <div class="partner-wrapper" ref="partner">
            <div
              v-for="(item, index) in partner"
              :key="index"
              class="partner-img"
              :class="{ hover: item.url }"
              @click="$jump(item.url)"
              :title="item.url"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
          <div v-show="!taggle_show" class="taggle-more" @click="taggleShow">
            下拉了解更多<i class="iconfont">&#xe611;</i>
          </div>
          <div v-show="taggle_show" class="taggle-more" @click="taggleHide">
            收起更多<i class="iconfont">&#xe60c;</i>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container pc-wrap scroll-tag">
      <div class="pc-content">
        <div class="title">股东企业</div>
        <div class="subtitle">SHAREHOLDERS ENTERPRISE</div>
        <div class="fmix-align-sb gudong">
          <img src="@/assets/images/ad-4-01.png" alt="" />
          <img src="@/assets/images/ad-4-02.png" alt="" />
          <img src="@/assets/images/ad-4-03.png" alt="" />
          <img src="@/assets/images/ad-4-04.png" alt="" />
        </div>
      </div>
    </div> -->

    <!-- <div class="container pc-wrap scroll-tag" style="padding: 40px 0 60px">
      <div class="pc-content" style="max-width: 100%">
        <div class="title">会员服务</div>
        <div class="subtitle">共享空间共同富裕</div>
        <div>
          <img src="@/assets/images/bg-5.png" alt="" />
          <div class="member-icons">
            <div class="member-icon">
              <i class="iconfont">&#xe6c6;</i>
              <div>会员弹性福利</div>
            </div>
            <div class="member-icon">
              <i class="iconfont">&#xe63f;</i>
              <div>会员节日福利</div>
            </div>
            <div class="member-icon">
              <i class="iconfont">&#xe690;</i>
              <div>会员专属内购</div>
            </div>
            <div class="member-icon">
              <i class="iconfont">&#xe664;</i>
              <div>会员生活福利</div>
            </div>
            <div class="member-icon">
              <i class="iconfont">&#xe620;</i>
              <div>共同富裕分享机制</div>
            </div>
            <div class="member-icon">
              <i class="iconfont">&#xe624;</i>
              <div>大众创业项目</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container pc-wrap scroll-tag" style="padding: 40px 0 0">
      <div class="pc-content" style="max-width: 100%">
        <div class="title">会员服务</div>
        <div class="subtitle">共享空间共同富裕</div>
        <router-link to="/news/5">
          <img src="@/assets/images/bg-5-01.jpg" alt="" />
        </router-link>
      </div>
    </div>

    <div class="nav-scroll" :style="{ width: asideWith + 'px' }">
      <div class="nav-scroll-wrapper">
        <div
          v-for="(item, index) in scrollNavs"
          :key="index"
          class="nav-scroll-item"
          @click="navScrollTo(index)"
        >
          <i class="iconfont">&#xeb5e;</i>
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "@/components/earth/index";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import request from "@/utils/request";
import AsideNewsShort from "@/components/aside/newsShort";

export default {
  components: {
    Earth,
    Swiper,
    SwiperSlide,
    AsideNewsShort,
  },
  data() {
    return {
      bannerOptions: {
        on: {
          slideChangeTransitionStart: (e) => {
            this.bannerIndex = e.activeIndex;
          },
        },
      },
      bannerIndex: 0,
      bannerH: 500,
      boxWidth: 375,
      scrollNavs: [
        {
          top: 0,
          index: 0,
          title: "信鸽数字平台",
        },
        {
          top: 0,
          index: 1,
          title: "专项服务",
        },
        {
          top: 0,
          index: 2,
          title: "集成服务",
        },
        {
          top: 0,
          index: 3,
          title: "联盟合作伙伴",
        },
        // {
        //   top: 0,
        //   index: 3,
        //   title: "股东企业",
        // },
        {
          top: 0,
          index: 4,
          title: "会员服务",
        },
      ],
      asideWith: 0,
      proSwiperOptions: {
        slidesPerView: 2,
        centeredSlides: true,
        loop: true,
      },
      banner: [],
      stars: [],
      group: {},
      platform: [],
      platformIndex: 0,
      article: [],
      partner: [],
      partner_h5: [],
      partner_url: "",
      products: [],
      shareholders: [],
      taggle_show: false,
      partnerH: 0,
      partnerHDft: 216,
      partnerBoxH: 216,
    };
  },
  computed: {
    bannerSize() {
      let size = this.banner.length;
      if (this.stars && this.stars.length) {
        size++;
      }
      return size;
    },
  },
  created() {
    const dom = document.documentElement;
    const winW = dom.clientWidth;
    const winH = dom.clientHeight;
    this.bannerH = winH - 64;
    if (winW > winH) {
      this.boxWidth = Math.min(1100 - 200, winH);
    } else {
      this.boxWidth = winW;
    }

    this.asideWith = winW / 2 - 550;

    // if (typeof window.entryUrl === "undefined" || window.entryUrl === "") {
    //   window.entryUrl = location.href;
    // }
    // let url = /(Android)/i.test(navigator.userAgent)
    //   ? location.href
    //   : window.entryUrl;

    request.get("index").then((res) => {
      let data = res.data;
      this.banner = data.home_banner;
      this.stars = data.home_star_ring;

      this.partner = data.home_partner;

      setTimeout(() => {
        this.partnerH = this.$refs.partner.offsetHeight;
        if (this.partnerH < 216) {
          this.partnerHDft = this.partnerH;
          this.partnerBoxH = this.partnerH;
        }
      }, 3000);

      // setTimeout(() => {
      //   this.group = data.home_group_profile[0];
      //   this.platform = data.home_platform;
      //   this.products = data.home_products;
      //   this.article = data.article.list;
      //   this.shareholders = data.home_shareholder;
      //   this.partner = data.home_partner;
      //   this.partner_h5 = data.home_H5_partner;
      //   if (data.home_partner_link[0]) {
      //     this.partner_url = data.home_partner_link[0].url;
      //   }
      // }, 300);

      if (this.stars && this.stars.length) {
        this.onVisibilityChange();
      }
    });
  },
  mounted() {
    request.get("wechat/config", { url: "https://www.runzehui.net/" }).then(res=>{
      let data = res.data;
      data.jsApiList = ['wx-open-launch-weapp'];
      data.openTagList = ['wx-open-launch-weapp'];
      console.log(data)
    })

    setTimeout(() => {
      this.setScrollNavs();
    });
  },
  methods: {
    taggleHide() {
      this.partnerBoxH = this.partnerHDft;
      this.taggle_show = false;
    },
    taggleShow() {
      this.partnerBoxH = this.partnerH;
      this.taggle_show = true;
    },
    setScrollNavs() {
      let doms = document.querySelectorAll(".scroll-tag");
      for (var i = 0; i < this.scrollNavs.length; i++) {
        this.scrollNavs[i].top = doms[this.scrollNavs[i].index].offsetTop;
      }
    },
    navScrollTo(i) {
      let top = this.scrollNavs[i].top;
      window.scrollTo({
        top: Math.max(top - 64, 0),
        behavior: "smooth",
      });
    },
    onVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.$refs.earth.earthPause();
        } else {
          this.$refs.earth.earthPlay();
        }
      });
    },
    handleClickSlide(e, index) {
      const { url } = this.products[index];
      url && this.$jump(url);
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  height: 100vh;
  background: #090d32;
  overflow: hidden;
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;

  .swiper-pagination-item {
    display: inline-block;
    width: 36px;
    height: 6px;
    background: #42404e;
    margin: 10px;

    &.on {
      background: #fff;
    }
  }
}

.banner-item {
  height: 100%;
  position: relative;

  &.img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.banner-earth {
  background: url("~@/assets/images/earth_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}

.earth-title-box {
  position: absolute;
  top: 15%;
  z-index: 1;
}

.earth-title-sub {
  font-size: 36px;
  color: #fff;
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.earth-title {
  font-size: 44px;
  letter-spacing: 6px;
  color: #fff;
  font-weight: 100;
  opacity: 0.7;

  .mark {
    color: #eb8d48;
  }
}

.earth-title-m {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;

  .mark {
    color: #f2ab3f;
  }

  .earth-subtitle {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 6px;
    margin-top: 10px;
  }
}

.earth-box {
  position: absolute;
  top: -10%;
  right: -15%;
}

.pt-row {
  padding: 0 20px;

  .pt-item {
    text-align: center;

    .pt-num {
      display: inline-block;
      position: relative;
      font-size: 32px;
      color: #000054;
      font-weight: bold;

      .iconfont {
        font-size: 18px;
        position: absolute;
        top: -4px;
        right: -22px;
      }
    }
  }
}

.box-serve {
  position: relative;
  .box-serve-item {
    display: flex;
  }
}

.fix {
  display: block;
  width: 200px;
  position: absolute;

  &.right {
    right: -20px;
    top: 0;
    transform: translateX(100%);
  }
}

@media (max-width: 1600px) {
  .fix {
    display: none;
  }

  .nav-scroll {
    .nav-scroll-wrapper {
      display: none;
    }
  }
}

.nav-scroll {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-50%);

  .nav-scroll-wrapper {
    width: 200px;

    .nav-scroll-item {
      font-size: 16px;
      color: #707070;
      text-shadow: 0 0 1px #fff;
      padding: 6px;
      cursor: pointer;

      &:hover {
        color: #0f0963;
      }
    }
  }
}

.box-pt {
  position: relative;
  overflow: hidden;

  .box-pt-img {
    margin-left: 250px;
  }

  .fix-pt {
    position: absolute;
    top: 0;
    right: 310px;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .fix-pt-row {
    height: 136px;
    color: #fff;
    background: rgba(0, 36, 171, 0.75);

    .name {
      width: 250px;
      font-size: 24px;
      line-height: 136px;
      letter-spacing: 2px;
      text-align: center;
    }

    .fix-pt-icons {
      padding: 0 30px;
      position: relative;

      .bg {
        width: 200px;
        height: 200px;
        position: absolute;
        top: -30px;
        right: 0;
        left: 0;
        margin: auto;
        opacity: 0.1;
      }
    }

    .serve-icon-item {
      min-width: 50px;
      text-align: center;
      padding: 0 5px;
      cursor: pointer;

      .serve-icon {
        width: 50px;
        height: 50px;
        margin: auto;
        margin-bottom: 5px;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 50px;
          height: 50px;
          margin: auto;
          opacity: 0.9;
          transition: width 0.3s, height 0.3s;
          &:hover {
            opacity: 1;
          }
        }
      }
      .serve-icon-name {
        color: #fff;
        font-size: 12px;
        letter-spacing: 2px;
      }
    }
  }
}

.serve-box {
  position: relative;

  .serve-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover .serve-wrapper {
      // height: 280px;
    }
  }

  .serve-title {
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .serve-title-sub {
    font-size: 16px;
    color: #fff;
    text-align: center;
    letter-spacing: 4px;
    margin-bottom: 20px;
  }

  .serve-wrapper {
    width: 100%;
    height: 0;
    height: 280px;
    overflow: hidden;
    transition: height 0.3s;
  }

  .serve-icons {
    display: flex;
    justify-content: center;
    .serve-icon-item {
      min-width: 100px;
      text-align: center;
      margin-bottom: 30px;
      padding: 0 5px;
      cursor: pointer;

      .serve-icon {
        width: 70px;
        height: 70px;
        margin: auto;
        margin-bottom: 5px;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 60px;
          height: 60px;
          margin: auto;
          opacity: 0.9;
          transition: width 0.3s, height 0.3s;
          &:hover {
            width: 70px;
            height: 70px;
            opacity: 1;
          }
        }
      }
      .serve-icon-name {
        color: #fff;
        font-size: 12px;
        letter-spacing: 2px;
      }
    }
  }

  .serve-btn {
    display: block;
    width: 150px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    margin: 44px auto 0;
    cursor: pointer;
  }
}

.zx-box {
  display: flex;
  position: relative;
}

.fuwu-row {
  display: flex;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    border-bottom: 0;
  }
}

.fuwu-item {
  width: 16.6%;
  box-sizing: border-box;
  padding: 40px 0;
  position: relative;

  &:first-of-type .fuwu-item-wrap {
    border-left: 0;
  }

  &:hover {
    .fuwu-mask {
      opacity: 1;
    }
  }
}

.fuwu-item-wrap {
  width: 100%;
  text-align: center;
  padding: 40px 0;
  border-left: 1px solid #eee;
}

.fuwu-icon {
  height: 68px;
  margin: 0 auto 30px;
  img {
    display: block;
    width: auto;
    height: 100%;
    margin: auto;
  }
}

.fuwu-name {
  font-size: 16px;
  color: #c4c4c4;
  letter-spacing: 2px;
}

.fuwu-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0f0963;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;

  .fuwu-mask-name {
    margin-top: 80px;
    text-align: center;
  }

  .fuwu-mask-btn {
    width: 120px;
    position: absolute;
    right: 0;
    bottom: 40px;
    left: 0;
    margin: auto;
    border: 1px solid #fff;
    text-align: center;
    font-size: 16px;
    line-height: 2;
  }
}

.gudong {
  img {
    width: 23.5%;
  }
}

.member-icons {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -100px;
  padding-bottom: 30px;

  .member-icon {
    width: 140px;
    margin: 0 6px;
    padding: 30px 0;
    text-align: center;
    background: #fff;
    border: 1px solid #f5f5f5;
    font-size: 16px;
    color: #999;
    border-radius: 4px;

    &:hover {
      background: #100a64;
      color: #fff;
      border-color: #100a64;
      .iconfont {
        color: #fff;
      }
    }

    .iconfont {
      display: inline-block;
      font-size: 80px;
      margin-bottom: 20px;
      color: #100a64;
    }
  }
}

.partner-box {
  height: 216px;
  margin-top: 30px;
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;

  &.taggle {
    height: 432px;
  }

  .partner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .partner-img {
    width: auto;
    height: 84px;
    margin: 12px;
    border-radius: 10px;
    overflow: hidden;

    &.hover {
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.taggle-more {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 13px;
  line-height: 40px;
  text-align: center;
  color: #0f0a66;
  cursor: pointer;
  background: #fff;

  i.iconfont {
    font-size: 18px;
    margin-left: 10px;
  }
}

.intro {
  .intro-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .content {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 2px;
    margin-bottom: 20px;
    width: 100%;
    white-space: pre-wrap;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.link {
  font-size: 14px;
  color: $link;
  cursor: pointer;
}

.fuli {
  padding: 0 120px;
  .fuli-img {
    flex: 1.8;
    margin-right: 100px;
  }
  .fuli-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .fuli-btn {
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    padding: 16px 0;
    background: #fff;
    border-radius: 10px;

    &.on,
    &:hover {
      color: $link;
      box-shadow: $hover-shadow;
    }
  }
}

.hover {
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: $hover-shadow;
    position: relative;
    top: -2px;
  }
}

.pro-item {
  margin-left: 10px;
  border-radius: 10px;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }
}

.news-item {
  margin-left: 20px;
  padding: 20px 15px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0;
  }

  .news-cate {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .news-img {
    width: 100%;
    margin-bottom: 20px;
    background: #eee;
    border-radius: 10px;
    overflow: hidden;
  }

  .news-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .news-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.company-item {
  margin-left: 100px;

  &:first-of-type {
    margin-left: 0;
  }
}

.partner {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px dashed #eee;

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: auto;
      height: 80px;
      margin: 10px 15px;
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 320px) and (max-width: 430px) {
  .banner-earth {
    background-position: -500px 0;
  }
  .earth-box {
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .box-serve {
    .box-serve-item {
      display: block;
    }
  }

  .fuwu-row {
    flex-wrap: wrap;
  }

  .fuwu-item {
    width: 50%;
  }

  .gudong {
    flex-wrap: wrap;
    img {
      width: 49%;
      margin-bottom: 6px;
    }
  }

  .member-icons {
    margin-top: -30px;
    flex-wrap: wrap;

    .member-icon {
      width: 140px;
    }
  }

  .intro {
    display: block;
    .intro-title {
      display: inline-block;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      padding: 2px 10px;
      color: #fff;
      background: #011167;
      border-radius: 5px;
    }
  }

  .company-item {
    margin: 0 2px;
  }

  .partner {
    .row {
      img {
        height: 50px;
        margin: 5px 0;
      }
    }
  }

  .fuli-m {
    .fuli-m-item {
      float: left;
      width: 49%;
      position: relative;
      margin-right: 2%;
      margin-bottom: 10px;
      background: #ccc;
      border-radius: 6px;
      overflow: hidden;

      &:nth-child(2n) {
        margin-right: 0;
      }

      img {
        display: block;
        widows: 100%;
      }

      .fuli-m-title {
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 10px;
        font-size: 16px;
        color: #fff;
        background: #011167;
        box-sizing: border-box;

        .more {
          font-size: 12px;
        }
      }
    }
  }

  .pro-m {
    .swiper-slide {
      transition: 300ms;
      transform: scale(0.9);
      border-radius: 10px;
      overflow: hidden;
    }

    .swiper-slide-active {
      transform: scale(1);
      box-shadow: $hover-shadow;
    }
  }

  .news-item {
    padding: 0;
    margin-bottom: 30px;
    background: none;

    .news-img {
      border-radius: 0;
      background: #999;
      margin-bottom: 0;
    }
    .news-info {
      background: #fff;
      padding: 10px 10px 1px;
    }

    .news-title {
      margin-bottom: 0;
      color: #011168;
    }

    .news-content {
      margin-top: 10px;
    }
  }

  .zx-box {
    display: block;
  }
}
</style>